// import logo from "./assets/images/.svg";
import { React, useState, useCallback, useEffect, useRef } from "react";
import "./styles/main.scss";
import logo from "./assets/images/logo-fluid-ui-white.svg";
import logoGithub from "./assets/images/logo-github.svg";
import iconDocs from "./assets/images/icon-docs.svg";

const useResize = (myRef) => {
  const [width, setWidth] = useState(0);
  const [fontSize, setFontSize] = useState(0);

  const handleResize = useCallback(() => {
    setWidth(myRef.current.offsetWidth);
    setFontSize(window.getComputedStyle(myRef.current).fontSize);
  }, [myRef]);

  useEffect(() => {
    window.addEventListener("load", handleResize);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("load", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef, handleResize]);

  return { width, fontSize };
};

const PreviewSpacerItem = (props) => {
  const componentRef = useRef();
  const { width } = useResize(componentRef);

  return (
    <div className="card | align-self-stretch align-items-center">
      <p className="d-flex align-items-center">
        <span className="fs-600">{props.num}</span>
        <span className="demo__value">{width}px</span>
      </p>
      <span ref={componentRef} className={"demo__bar ps-" + props.num + " spacer-bar-" + props.num}></span>
    </div>
  );
};

function SpacersPreview() {
  const spacers = ["100", "200", "300", "400", "500", "600", "700", "800", "900", "1000"];
  const listItems = spacers.map((number) => <PreviewSpacerItem num={number} key={number} />);

  return (
    <article className="py-700 | flow">
      <div>
        <h2 className="h2 mb-100">Spacers</h2>
        <p>
          Responsive margins, paddings, gaps, border-radius, box-shadows and more. Similiar to bootstrap classes but ... without
          breakpoints. Resize the viewport to see how it changes. Calculation based on Viewport Width and CSS clamp()
        </p>
      </div>
      <div className="demo__grid">{listItems}</div>
    </article>
  );
}

const PreviewFontSizeItem = (props) => {
  const componentRef = useRef();
  const { fontSize } = useResize(componentRef);

  return (
    <div className="card | d-flex align-self-stretch align-items-center">
      <p className="d-flex align-items-center">
        <span ref={componentRef} className={"fs-" + props.num}>
          fs-{props.num}
        </span>
        <span className="demo__value">{fontSize}</span>
      </p>
    </div>
  );
};

function FontSizesPreview() {
  const fontSizes = ["100", "200", "300", "400", "500", "600", "700", "800", "900", "1000"];
  const listItems = fontSizes.map((number) => <PreviewFontSizeItem num={number} key={number} />);

  return (
    <article className="py-700 | flow">
      <div>
        <h2 className="h2 mb-100">Typography</h2>
        <p>Fluid font sizes, similiar to spaceings - no breakpoint. All CSS classes prepared for you, feel free to add more.</p>
      </div>
      <div className="demo__grid">{listItems}</div>
    </article>
  );
}

function ColorsPreview() {
  const grays = ["100", "200", "300", "400", "500", "600", "700", "800", "900", "1000"];
  const listGreys = grays.map((number) => (
    <div>
      <div className="fs-200">gray-{number}</div>
      <div className={"card align-self-stretch py-500 px-900 bg-gray-" + number}></div>
    </div>
  ));

  const showHue = (event) => {
    document.documentElement.style.setProperty("--g-color-primary-h", event.target.value);
  };

  return (
    <article className="py-700 | flow">
      <div>
        <h2 className="h2 mb-100">Colors</h2>
        <p>Neutral (grays) colors follows the primary color. Change HUE value to see how it affect the grays.</p>
      </div>
      <div className=" | flow">
        <div className="d-flex align-items-center flex-wrap gap-400 card bg-gray-800">
          <label for="primary-hue" className="text-white">
            Hue (primary color)
          </label>
          <input type="range" onChange={showHue} min={0} max={360} step={1} name="primary-hue" className="demo__primary-hue"></input>
          <div className="p-300 bg-primary rounded-400 text-white"></div>
        </div>
        <h3 className="h3">Primary</h3>
        <div className="demo__grid">
          <div>
            <div className="fs-200">primary</div>
            <div className="card align-self-stretch py-500 px-900 bg-primary"></div>
          </div>
          <div>
            <div className="fs-200">primary-light</div>
            <div className="card align-self-stretch py-500 px-900 bg-primary-light"></div>
          </div>
          <div>
            <div className="fs-200">primary-dark</div>
            <div className="card align-self-stretch py-500 px-900 bg-primary-dark"></div>
          </div>
        </div>
        <h3 className="h3">Gray</h3>
        <div className="demo__grid">{listGreys}</div>
      </div>
    </article>
  );
}

function ShadowPreview() {
  const shadows = ["100", "200", "300", "400", "500"];
  const shadowItems = shadows.map((number) => <div className={"card shadow-" + number}>shadow-{number}</div>);

  return (
    <article className="py-700 | flow">
      <div>
        <h2 className="h2 mb-100">Shadows</h2>
        <p>Size responsive, color follows the primary color</p>
      </div>
      <div className="card2">
        <div className="vstack gap-600">{shadowItems}</div>
      </div>
    </article>
  );
}

function RoundedPreview() {
  const rounded = ["100", "200", "300", "400", "500", "full"];
  const roundedItems = rounded.map((number) => <div className={"card rounded-" + number}>{number}</div>);

  return (
    <article className="py-700 | flow">
      <div>
        <h2 className="h2 mb-100">Rounded</h2>
        <p>Border radius adjust automatically to viewport width.</p>
      </div>
      <div className="card2">
        <div className="d-flex flex-wrap gap-600">{roundedItems}</div>
      </div>
    </article>
  );
}

function App() {
  return (
    <>
      <section className="demo__title">
        <div className="wrapper">
          <header className="app-header">
            <h1>
              <img src={logo} alt="Fluid User Interface" width="140" />
            </h1>
            <nav className="nav">
              <a href="https://fluid-ui.michalszymczak.com/docs/" className="text-white">
                <img src={iconDocs} alt="Docs" width="30" />
              </a>
              <a href="https://github.com/michal-szymczak-ui-developer/fluid-ui">
                <img src={logoGithub} alt="github link" width="30" />
              </a>
            </nav>
          </header>
          <p className="h1 text-gray-100 py-900">
            Fluid spacers, typography and colors.
            <br />
            CSS directories structure & design tokens.
            <br />
            Fully customizable. No JS.
          </p>
        </div>
      </section>
      <main className="wrapper">
        <SpacersPreview />
        <FontSizesPreview />
        <ColorsPreview />
        <ShadowPreview />
        <RoundedPreview />
      </main>
      <footer className="py-600">
        <div className="wrapper">
          <nav className="nav">
            <a href="https://github.com/michal-szymczak-ui-developer/fluid-ui" className="text-primary">
              Github Repository
            </a>
            <a href="https://fluid-ui.michalszymczak.com/docs/" className="text-primary">
              Documentation
            </a>
          </nav>
        </div>
      </footer>
    </>
  );
}

export default App;
